import {api} from 'dicomweb-client';
import DICOMWeb from './DICOMWeb';

// These should be overridden by the implementation
let user = {
  userLoggedIn: () => false,
  getUserId: () => null,
  getName: () => null,
  getAccessToken: (accessToken) => {
    const config = { url: ' ', headers: {} };

    const dicomWeb = new api.DICOMwebClient(config);
    return dicomWeb.getAccessToken(DICOMWeb.getLoginAccessHeader(accessToken)).then((result) => result);
  },
  getUsernameToken: () => {
    const config = { url: ' ', headers: {} };

    const dicomWeb = new api.DICOMwebClient(config);
    return dicomWeb.getUsernameToken().then((result) => result);
  },
  pingWebService: () => {
    const config = { url: ' ', headers: {} };

    const dicomWeb = new api.DICOMwebClient(config);
    return dicomWeb.pingWebService().then((result) => result);
  },
  checkUsernameAvailability: (data) => {
    const config = { url: ' ', headers: {} };
    const dicomWeb = new api.DICOMwebClient(config);
    return dicomWeb.checkUsernameAvailability(data).then((result) => result);
  },
  checkRecordsMatch: (data) => {
    const config = { url: ' ', headers: {} };
    const dicomWeb = new api.DICOMwebClient(config);
    return dicomWeb.checkRecordsMatch(data).then((result) => result);
  },
  finishCreatingUser: (data) => {
    const config = { url: ' ', headers: {} };
    const dicomWeb = new api.DICOMwebClient(config);
    return dicomWeb.finishCreatingUser(data).then((result) => result);
  },
  changePassword: (url, credentials) => {
    const config = {
      url,
      headers: DICOMWeb.getAuthorizationHeader(credentials),
    };

    const dicomWeb = new api.DICOMwebClient(config);
    return dicomWeb.changePassword(url, credentials).then((result) => result);
  },
  login: (url, credentials) => {
    const config = {
      url,
      headers: DICOMWeb.getLoginAuthorizationHeader(credentials),
    };

    const dicomWeb = new api.DICOMwebClient(config);
    return dicomWeb.login(url).then((result) => result);
  },
  logout: () => new Promise((resolve, reject) => reject()),
  recover: (url, credentials) => {
    const config = {
      url,
      headers: DICOMWeb.getLoginAuthorizationHeader(credentials),
    };

    const dicomWeb = new api.DICOMwebClient(config);
    return dicomWeb.recover(url).then((result) => result);
  },
  recoverGuid: (url, credentials, guid) => {
    const config = {
      url,
      headers: DICOMWeb.getLoginAuthorizationHeader(credentials),
    };

    const dicomWeb = new api.DICOMwebClient(config);
    return dicomWeb.recoverGuid(url, guid).then((result) => result);
  },
  getData: (key) => null,
  setData: (key, value) => null,
};

export default user;
