const config = {
  // default: '/'
  routerBasename: '/',
  extensions: [],
  showStudyList: true,
  // dev
  // servers: {
  //   dicomWeb: [
  //     {
  //       name: 'DCM4CHEE',
  //       authRoot: 'http://localhost:2711/authorization',
  //       wadoUriRoot: 'http://localhost:2711/wadouri',
  //       qidoRoot: 'http://localhost:2711/qidors',
  //       wadoRoot: 'http://localhost:2711/wadors',
  //       qidoSupportsIncludeField: true,
  //       imageRendering: 'wadors',
  //       thumbnailRendering: 'wadors',
  //       requestOptions: {
  //         requestFromBrowser: true,
  //       },
  //     },
  //   ],
  // },
  //
  // prod
  servers: {
    dicomWeb: [
      {
        name: 'DicomCloud',
        authRoot: process.env.PACS_API_URL + '/authorization',
        wadoUriRoot: process.env.PACS_API_URL + '/wadouri',
        qidoRoot: process.env.PACS_API_URL + '/qidors',
        wadoRoot: process.env.PACS_API_URL + '/wadors',
        qidoSupportsIncludeField: true,
        imageRendering: 'wadors',
        thumbnailRendering: 'wadors',
        requestOptions: {
          requestFromBrowser: true,
        },
      },
    ],
  },
  // Extensions should be able to suggest default values for these?
  // Or we can require that these be explicitly set
  hotkeys: [
    // ~ Global
    {
      commandName: 'incrementActiveViewport',
      label: 'Next Image Viewport',
      keys: [''],
    },
    {
      commandName: 'decrementActiveViewport',
      label: 'Previous Image Viewport',
      keys: [''],
    },
    // Supported Keys: https://craig.is/killing/mice
    // ~ Cornerstone Extension
    { commandName: 'rotateViewportCW', label: 'Rotate Right', keys: ['r'] },
    { commandName: 'rotateViewportCCW', label: 'Rotate Left', keys: ['l'] },
    { commandName: 'invertViewport', label: 'Invert', keys: ['i'] },
    {
      commandName: 'flipViewportVertical',
      label: 'Flip Horizontally',
      keys: ['h'],
    },
    {
      commandName: 'flipViewportHorizontal',
      label: 'Flip Vertically',
      keys: ['v'],
    },
    { commandName: 'scaleUpViewport', label: 'Zoom In', keys: ['+'] },
    { commandName: 'scaleDownViewport', label: 'Zoom Out', keys: ['-'] },
    { commandName: 'fitViewportToWindow', label: 'Zoom to Fit', keys: ['='] },
    { commandName: 'resetViewport', label: 'Reset', keys: ['space'] },
    // clearAnnotations
    { commandName: 'nextImage', label: 'Next Image', keys: ['down'] },
    { commandName: 'previousImage', label: 'Previous Image', keys: ['up'] },
    // firstImage
    // lastImage
    {
      commandName: 'nextViewportDisplaySet',
      label: 'Next Series',
      keys: ['right'],
    },
    {
      commandName: 'previousViewportDisplaySet',
      label: 'Previous Series',
      keys: ['left'],
    },
    // ~ Cornerstone Tools
    { commandName: 'setZoomTool', label: 'Zoom', keys: ['z'] },
  ],
};
export default config;
