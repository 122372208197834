import DICOMWeb from '../DICOMWeb';
import { api } from 'dicomweb-client';

export default function getPatientOlderStudies(server, data) {
  const config = {
    url: server.qidoRoot,
    headers: DICOMWeb.getAuthorizationHeader(server),
  };

  const dicomWeb = new api.DICOMwebClient(config);
  return dicomWeb.getPatientOlderStudies(data).then((results) => results);
}
