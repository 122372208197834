import { Icon } from './../../elements/Icon';
import React from 'react';
import { withTranslation } from '../../utils/LanguageProvider';

function StudyListLoadingText({ t: translate }) {
  return (
    <div className="loading-text">
      {translate('Loading')}...{' '}
      <Icon style={{ animation: 'rotate 0.6s linear infinite' }} name="circle-notch" />
    </div>
  );
}

const connectedComponent = withTranslation('StudyListLoadingText')(StudyListLoadingText);

export { connectedComponent as StudyListLoadingText };
