import {api} from 'dicomweb-client';
import DICOMWeb from './DICOMWeb';

let report = {
  fetchReport: (data) => {
    const config = {
      url: true,
      headers: DICOMWeb.getAuthorizationHeader(),
    };

    const dicomWeb = new api.DICOMwebClient(config);
    return dicomWeb.fetchReport(data).then((result) => result);
  },
  fetchStudyStatus: (data) => {
    const config = {
      url: true,
      headers: DICOMWeb.getAuthorizationHeader(),
    };

    const dicomWeb = new api.DICOMwebClient(config);
    return dicomWeb.fetchStudyStatus(data).then((result) => result);
  },
  fetchReportFormat: (data) => {
    const config = {
      url: true,
      headers: DICOMWeb.getAuthorizationHeader(),
    };

    const dicomWeb = new api.DICOMwebClient(config);
    return dicomWeb.fetchReportFormat(data).then((result) => result);
  },
  fetchReportResults: (data) => {
    const config = {
      url: true,
      headers: DICOMWeb.getAuthorizationHeader(),
    };

    const dicomWeb = new api.DICOMwebClient(config);
    return dicomWeb.fetchReportResults(data).then((result) => result);
  },
  fetchReportOptions: (data) => {
    const config = {
      url: true,
      headers: DICOMWeb.getAuthorizationHeader(),
    };

    const dicomWeb = new api.DICOMwebClient(config);
    return dicomWeb.fetchReportOptions(data).then((result) => result);
  },
  fetchPatientFromStudyUid: (data) => {
    const config = {
      url: true,
      headers: DICOMWeb.getAuthorizationHeader(),
    };

    const dicomWeb = new api.DICOMwebClient(config);
    return dicomWeb.fetchPatientFromStudyUid(data).then((result) => result);
  },
  saveReportFormat: (data) => {
    const config = {
      url: true,
      headers: DICOMWeb.getAuthorizationHeader(),
    };

    const dicomWeb = new api.DICOMwebClient(config);
    return dicomWeb.saveReportFormat(data).then((result) => result);
  },
  saveReportResults: (data) => {
    const config = {
      url: true,
      headers: DICOMWeb.getAuthorizationHeader(),
    };

    const dicomWeb = new api.DICOMwebClient(config);
    return dicomWeb.saveReportResults(data).then((result) => result);
  },
  saveReport: (data) => {
    const config = {
      url: true,
      headers: DICOMWeb.getAuthorizationHeader(),
    };

    const dicomWeb = new api.DICOMwebClient(config);
    return dicomWeb.saveReport(data).then((result) => result);
  },
  deleteSavedReport: (data) => {
    const config = {
      url: true,
      headers: DICOMWeb.getAuthorizationHeader(),
    };

    const dicomWeb = new api.DICOMwebClient(config);
    return dicomWeb.deleteSavedReport(data).then((result) => result);
  },

  fetchReport: (data) => {
    const config = {
      url: true,
      headers: DICOMWeb.getAuthorizationHeader(),
    };

    const dicomWeb = new api.DICOMwebClient(config);
    return dicomWeb.fetchReport(data).then((result) => result);
  },

  fetchStructuredReportEntries: (data) => {
    const config = {
      url: true,
      headers: DICOMWeb.getAuthorizationHeader(),
    };

    const dicomWeb = new api.DICOMwebClient(config);
    return dicomWeb.fetchStructuredReportEntries(data).then((result) => result);
  },

  sendEmail: (data) => {
    const config = {
      url: true,
      headers: DICOMWeb.getAuthorizationHeader(),
    };

    const dicomWeb = new api.DICOMwebClient(config);
    return dicomWeb.sendEmail(data).then((result) => result);
  },

  submitGeneratedPDF: (data) => {
    const config = {
      url: true,
      headers: DICOMWeb.getAuthorizationHeader(),
    };

    const dicomWeb = new api.DICOMwebClient(config);
    return dicomWeb.submitGeneratedPDF(data).then((result) => result);
  },

  saveReportHtmlElement: (data) => {
    const config = {
      url: true,
      headers: DICOMWeb.getAuthorizationHeader(),
    };

    const dicomWeb = new api.DICOMwebClient(config);
    return dicomWeb.saveReportHtmlElement(data).then((result) => result);
  },

  validatePhysicianPasscode: (data) => {
    const config = {
      url: true,
      headers: DICOMWeb.getAuthorizationHeader(),
    };

    const dicomWeb = new api.DICOMwebClient(config);
    return dicomWeb.validatePhysicianPasscode(data).then((result) => result);
  },

  getPhysicianSignature: (data) => {
    const config = {
      url: true,
      headers: DICOMWeb.getAuthorizationHeader(),
    };

    const dicomWeb = new api.DICOMwebClient(config);
    return dicomWeb.getPhysicianSignature(data).then((result) => result);
  },

  isReportSigned: (data) => {
    const config = {
      url: true,
      headers: DICOMWeb.getAuthorizationHeader(),
    };

    const dicomWeb = new api.DICOMwebClient(config);
    return dicomWeb.isReportSigned(data).then((result) => result);
  },

  getPhysicianIDFromUsername: (data) => {
    const config = {
      url: true,
      headers: DICOMWeb.getAuthorizationHeader(),
    };

    const dicomWeb = new api.DICOMwebClient(config);
    return dicomWeb.getPhysicianIDFromUsername(data).then((result) => result);
  },


  getStudyPdfFilenameData: (data) => {
    const config = {
      url: true,
      headers: DICOMWeb.getAuthorizationHeader(),
    };

    const dicomWeb = new api.DICOMwebClient(config);
    return dicomWeb.getStudyPdfFilenameData(data).then((result) => result);
  },

  updatePatientInfo: (data) => {
    const config = {
      url: true,
      headers: DICOMWeb.getAuthorizationHeader(),
    };

    const dicomWeb = new api.DICOMwebClient(config);
    return dicomWeb.updatePatientInfo(data).then((result) => result);
  },

  saveTemplate: (data) => {
    const config = {
      url: true,
      headers: DICOMWeb.getAuthorizationHeader(),
    };

    const dicomWeb = new api.DICOMwebClient(config);
    return dicomWeb.saveTemplate(data).then((result) => result);
  },

  deleteTemplate: (data) => {
    const config = {
      url: true,
      headers: DICOMWeb.getAuthorizationHeader(),
    };

    const dicomWeb = new api.DICOMwebClient(config);
    return dicomWeb.deleteTemplate(data).then((result) => result);
  },

  getStaffTemplates: (data) => {
    const config = {
      url: true,
      headers: DICOMWeb.getAuthorizationHeader(),
    };

    const dicomWeb = new api.DICOMwebClient(config);
    return dicomWeb.getStaffTemplates(data).then((result) => result);
  },
  markStudyComplete: (data) => {
    const config = {
      url: true,
      headers: DICOMWeb.getAuthorizationHeader(),
    };

    const dicomWeb = new api.DICOMwebClient(config);
    return dicomWeb.markStudyComplete(data).then((result) => result);
  },
  signReport: (data) => {
    const config = {
      url: true,
      headers: DICOMWeb.getAuthorizationHeader(),
    };

    const dicomWeb = new api.DICOMwebClient(config);
    return dicomWeb.signReport(data).then((result) => result);
  },

  removeStudySignatureData: (data) => {
    const config = {
      url: true,
      headers: DICOMWeb.getAuthorizationHeader(),
    };

    const dicomWeb = new api.DICOMwebClient(config);
    return dicomWeb.removeStudySignatureData(data).then((result) => result);
  },

  fetchStudyData: (data) => {
    const config = {
      url: true,
      headers: DICOMWeb.getAuthorizationHeader(),
    };

    const dicomWeb = new api.DICOMwebClient(config);
    return dicomWeb.fetchStudyData(data).then((result) => result);
  },

  fetchStudyRedirectDetails: (data) => {
    const config = {
      url: true,
      headers: DICOMWeb.getAuthorizationHeader(),
    };

    const dicomWeb = new api.DICOMwebClient(config);
    return dicomWeb.fetchStudyRedirectDetails(data).then((result) => result);
  },

  fetchStudyIdFromUID: (data) => {
    const config = {
      url: true,
      headers: DICOMWeb.getAuthorizationHeader(),
    };

    const dicomWeb = new api.DICOMwebClient(config);
    return dicomWeb.fetchStudyIdFromUID(data).then((result) => result);
  },

  fetchStudyTypeFromUID: (data) => {
    const config = {
      url: true,
      headers: DICOMWeb.getAuthorizationHeader(),
    };

    const dicomWeb = new api.DICOMwebClient(config);
    return dicomWeb.fetchStudyTypeFromUID(data).then((result) => result);
  },

  handleDownloadStudyDICOMFile: (data) => {
    const config = {
      url: true,
      headers: DICOMWeb.getAuthorizationHeader(),
    };

    const dicomWeb = new api.DICOMwebClient(config);
    return dicomWeb.handleDownloadStudyDICOMFile(data).then((result) => result);
  },

  fetchReportAccessLog: (data) => {
    const config = {
      url: true,
      headers: DICOMWeb.getAuthorizationHeader(),
    };

    const dicomWeb = new api.DICOMwebClient(config);
    return dicomWeb.fetchReportAccessLog(data).then((result) => result);
  },

  fetchPhysicianPasscodePermission: (data) => {
    const config = {
      url: true,
      headers: DICOMWeb.getAuthorizationHeader(),
    };

    const dicomWeb = new api.DICOMwebClient(config);
    return dicomWeb.fetchPhysicianPasscodePermission(data).then((result) => result);
  },

  fetchFaxInformation: (data) => {
    const config = {
      url: true,
      headers: DICOMWeb.getAuthorizationHeader(),
    };

    const dicomWeb = new api.DICOMwebClient(config);
    return dicomWeb.fetchFaxInformation(data).then((result) => result);
  },

  unsignReport: (data) => {
    const config = {
      url: true,
      headers: DICOMWeb.getAuthorizationHeader(),
    };

    const dicomWeb = new api.DICOMwebClient(config);
    return dicomWeb.unsignReport(data).then((result) => result);
  },
};

export default report;
