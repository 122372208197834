import {api} from 'dicomweb-client';
import DICOMWeb from '../DICOMWeb';

export default function getAvailableStudyDataFromUID({server, studyInstanceUID}) {
  const config = {
    url: server.qidoRoot,
    headers: DICOMWeb.getAuthorizationHeader(server),
  };

  const dicomWeb = new api.DICOMwebClient(config);
  return dicomWeb.getAvailableStudyDataFromUID(studyInstanceUID).then((results) => results);
}
