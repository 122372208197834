import fetchStudyAccessLog from './fetchStudyAccessLog';
import getAvailableStudyDataFromUID from './getAvailableStudyDataFromUID';
import getPatientOlderStudies from './getPatientOlderStudies';
import getStudyBoxData from './getStudyBoxData';
import markStudyComplete from './markStudyComplete';
import retrieveStudiesMetadata from './retrieveStudiesMetadata.js';
import {deleteStudyMetadataPromise, retrieveStudyMetadata} from './retrieveStudyMetadata.js';
import fetchStudyWorklists from './retrieveStudyWorklists';
import searchStudies from './searchStudies';
import {QIDO, WADO} from './services/';
import sortStudy from './sortStudy';
import updateStudyInformation from './updateStudyInformation';

const studies = {
  services: {
    QIDO,
    WADO,
  },
  fetchStudyWorklists,
  getPatientOlderStudies,
  loadingDict: {},
  retrieveStudyMetadata,
  deleteStudyMetadataPromise,
  retrieveStudiesMetadata,
  getStudyBoxData,
  searchStudies,
  markStudyComplete,
  sortStudy,
  getAvailableStudyDataFromUID,
  updateStudyInformation,
  fetchStudyAccessLog
};

export default studies;
