import PropTypes from 'prop-types';
import React from 'react';

export default function UseHook({ hook, args, onValue, this: self, children }) {
  const value = hook.apply(self, args || []);
  if (onValue) {
    onValue(value);
  }
  return <>{children}</>;
}
UseHook.propTypes = {
  hook: PropTypes.func.isRequired,
  args: PropTypes.array,
  onValue: PropTypes.func,
  this: PropTypes.any,
  children: PropTypes.node,
};
