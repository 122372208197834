import { SET_CURRENT_STUDY, SET_REPORT_WINDOW } from './../constants/ActionTypes.js';

const defaultState = {
  open: false,
  study: '',
};

/**
 * @param {Object} [state=defaultState]
 * @param {Object} action
 */
const viewer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_CURRENT_STUDY:
      return Object.assign({}, state, { study: action.data.study });
    case SET_REPORT_WINDOW:
      return Object.assign({}, state, { open: action.data.open });
    default:
      return state;
  }
};

export default viewer;
