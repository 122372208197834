import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ViewportContext from '../../utils/ViewportContext';
import './StudyBrowser.styl';
import { ThumbnailEntry } from './ThumbnailEntry';
import ThumbnailEntryDragSource from './ThumbnailEntryDragSource.js';

class StudyBrowser extends Component {
  static defaultProps = {
    studies: [],
    supportsDragAndDrop: true,
  };

  static propTypes = {
    studies: PropTypes.array.isRequired,
    supportsDragAndDrop: PropTypes.bool.isRequired,
    onThumbnailClick: PropTypes.func,
    onThumbnailDoubleClick: PropTypes.func,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      scrollViewport: null,
    };
    this.scrollViewportRefCallback = (scrollViewport) => {
      this.setState({
        scrollViewport,
      });
    };
  }

  handleAnchorClick = (id) => {
    const activeTab = sessionStorage['viewerActiveTab'] || 888;

    document.getElementById(`scrollable-study-thumbnails-${activeTab}`).scrollTop =
      document.getElementById(id)
        .offsetTop - 87.5;
  }

  render() {
    let anchorData = [];

    const studies = this.props.studies;
    const thumbnails = studies.map((study, studyIndex) => {
      return study.thumbnails.map((thumb, thumbIndex) => {
        if (this.props.supportsDragAndDrop) {

          if (thumbIndex === 0) {
            anchorData.push({ title: `Study ${studyIndex + 1}`, link: thumb.displaySetInstanceUid });
          }

          return (
            <ThumbnailEntryDragSource
              key={thumb.displaySetInstanceUid}
              {...study}
              {...thumb}
              index={thumbIndex}
              id={`${studyIndex}_${thumbIndex}`}
              onClick={this.props.onThumbnailClick}
              onDoubleClick={this.props.onThumbnailDoubleClick}
            />
          );
        } else {
          return (
            <div className="ThumbnailEntryContainer" data-cy="thumbnail-list">
              <ThumbnailEntry
                key={thumb.displaySetInstanceUid}
                {...study}
                {...thumb}
                index={thumbIndex}
                id={`${studyIndex}_${thumbIndex}`}
                onClick={this.props.onThumbnailClick}
                onDoubleClick={this.props.onThumbnailDoubleClick}
              />
            </div>
          );
        }
      });
    });

    const components = thumbnails.flat();
    return (
      <ViewportContext.Provider value={this.state.scrollViewport}>
        <div className="StudyBrowser">
          <div
            id={`scrollable-study-thumbnails-${this.props.studyInstanceUid}`}
            className="scrollable-study-thumbnails"
            ref={this.scrollViewportRefCallback}
          >
            <ul style={{ color: 'white', position: 'fixed', left: '-40px' }}>
              {anchorData.map(x => <li className="thumbnail-anchor" key={x.link} onClick={() => { this.handleAnchorClick(x.link) }}>{x.title}</li>)}
            </ul>
            {components}
          </div>
        </div>
      </ViewportContext.Provider>
    );
  }
}

export { StudyBrowser };
