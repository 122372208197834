import OHIF from '@ohif/core';
import asyncComponent from '../components/AsyncComponent.js';
import {withRouter} from 'react-router-dom';

const {urlUtil: UrlUtil} = OHIF.utils;

// Dynamic Import Routes (CodeSplitting)
const IHEInvokeImageDisplay = asyncComponent(() => import('./IHEInvokeImageDisplay.js'));                     /* webpackChunkName: "IHEInvokeImageDisplay" */
const TokenRouting = asyncComponent(() => import('./Token.js'));
const LoginRouting = asyncComponent(() => import('./Login.js'));
const RecoverRouting = asyncComponent(() => import('./Recover.js'));
const RecoverGuidRouting = asyncComponent(() => import('./RecoverGuid.js'));
const ReportRouting = asyncComponent(() => import('./reports/reportForm.js'));
const SettingsPage = asyncComponent(() => import('./newSettings/newSettings.js'));
const NewUserPage = asyncComponent(() => import('./NewUserRouting.js'));
const ViewerRouting = asyncComponent(() => import('./ViewerRouting.js'));                                     /* webpackChunkName: "ViewerRouting" */
const StandaloneTokenViewerRouting = asyncComponent(() => import('./StandaloneTokenViewerRouting.js'));       /* webpackChunkName: "ViewerRouting" */
const StudyListRouting = asyncComponent(() => import('../studylist/StudyListRouting.js'));                    /* webpackChunkName: "StudyListRouting" */
const StandaloneRouting = asyncComponent(() => import('./StandaloneRouting.js'));                             /* webpackChunkName: "StandaloneRouting" */
const ViewerLocalFileData = asyncComponent(() => import('../connectedComponents/ViewerLocalFileData.js'));    /* webpackChunkName: "ViewerLocalFileData" */
const ViewerUploadFileData = asyncComponent(() => import('../connectedComponents/ViewerUploadFileData.js'));  /* webpackChunkName: "ViewerUploadFileData" */
const AssignStudyPage = asyncComponent(() => import('../../src/routes/assignStudy/AssignStudyRouting'))
const AnalyticsPage = asyncComponent(() => import('../../src/routes/analytics/AnalyticsRouting'));

const reload = () => window.location.reload();

const ROUTES_DEF = {
  default: {
    token: {
      path: '/token/:token',
      component: withRouter(TokenRouting),
    },
    viewer: {
      path: '/viewer/:studyInstanceUids',
      component: withRouter(ViewerRouting),
    },
    standaloneTokenViewer: {
      path: '/standalone-viewer/:studyInstanceUids/:token',
      component: withRouter(StandaloneTokenViewerRouting),
    },
    loginViewer: {
      path: ['/login', '/'],
      component: withRouter(LoginRouting),
    },
    recoverViewer: {
      path: '/recover',
      component: withRouter(RecoverRouting),
    },
    recoverGuidViewer: {
      path: '/recover/guid/:guid',
      component: withRouter(RecoverGuidRouting),
    },
    standaloneViewer: {
      path: '/viewer',
      component: withRouter(StandaloneRouting),
    },
    list: {
      path: '/studylist',
      component: withRouter(StudyListRouting),
      condition: (appConfig) => {
        return appConfig.showStudyList !== undefined ? appConfig.showStudyList : true;
      },
    },
    report: {
      path: '/report/:studyInstanceUid',
      component: withRouter(ReportRouting),
    },
    assignStudy: {
      path: '/assign/:studyInstanceUid',
      component: withRouter(AssignStudyPage),
    },
    local: {
      path: '/local',
      component: withRouter(ViewerLocalFileData),
    },
    settings: {
      path: '/settings',
      component: withRouter(SettingsPage),
    },
    newUser: {
      path: '/newuser',
      component: withRouter(NewUserPage),
    },
    upload: {
      path: '/upload',
      component: withRouter(ViewerUploadFileData),
    },
    analytics: {
      path: '/analytics',
      component: withRouter(AnalyticsPage),
    },
    IHEInvokeImageDisplay: {
      path: '/IHEInvokeImageDisplay',
    },
  },
  gcloud: {
    viewer: {
      path:
        '/projects/:project/locations/:location/datasets/:dataset/dicomStores/:dicomStore/study/:studyInstanceUids',
      component: withRouter(ViewerRouting),
      condition: (appConfig) => {
        return !!appConfig.enableGoogleCloudAdapter;
      },
    },
  },
};

const getRoutes = (appConfig) => {
  const routes = [];
  for (let keyConfig in ROUTES_DEF) {
    const routesConfig = ROUTES_DEF[keyConfig];

    for (let routeKey in routesConfig) {
      const route = routesConfig[routeKey];
      const validRoute = typeof route.condition === 'function' ? route.condition(appConfig) : true;

      if (validRoute) {
        routes.push({
          path: route.path,
          Component: route.component,
        });
      }
    }
  }

  return routes;
};

const parseViewerPath = (appConfig = {}, server = {}, params) => {
  let viewerPath = ROUTES_DEF.default.viewer.path;
  if (appConfig.enableGoogleCloudAdapter) {
    viewerPath = ROUTES_DEF.gcloud.viewer.path;
  }

  const _paramsCopy = Object.assign({}, server, params);

  for (let key in _paramsCopy) {
    viewerPath = UrlUtil.paramString.replaceParam(viewerPath, key, _paramsCopy[key]);
  }

  return viewerPath;
};

export {getRoutes, parseViewerPath, reload};
