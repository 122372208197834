
import {Icon, notification} from 'antd';
import React, {useEffect, useState} from 'react';
import {Redirect, Route} from 'react-router-dom';
import {CSSTransition} from 'react-transition-group';
import * as RoutesUtil from '../src/routes/routesUtil';


// all possible routes/actions a user might access and which roles are allowed to access them
const routesAllowedUsers = {
  // pre-login routes which require no permissions
  '/': ['admin', 'physician', 'tech'],
  '/login': ['admin', 'physician', 'tech'],
  '/recover': ['admin', 'physician', 'tech'],
  '/recover/guid/:guid': ['admin', 'physician', 'tech'],

  // DICOM-file-viewer-related routes
  '/viewer': ['admin', 'physician', 'tech'],
  '/viewer/:studyInstanceUids': ['admin', 'physician', 'tech'],
  '/standalone-viewer/:studyInstanceUids/:token': ['admin', 'physician', 'tech'],

  // other routes related to the access of front-end forms
  '/studylist': ['admin', 'physician', 'tech'],
  '/report/:studyInstanceUid': ['admin', 'physician', 'tech'],
  '/assign/:studyInstanceUid': ['admin', 'physician', 'tech'],
  '/analytics': ['admin', 'physician', 'tech'],
  '/settings': ['admin'],
  '/upload': ['admin', 'physician', 'tech'],
  '/newuser': ['admin', 'physician', 'tech', 'unassigned'],

  // routes not related to accessing front-end forms (AFAIK)
  '/local': ['admin', 'physician', 'tech'],
  '/IHEInvokeImageDisplay': ['admin', 'physician', 'tech'],
  '/projects/:project/locations/:location/datasets/:dataset/dicomStores/:dicomStore/study/:studyInstanceUids': ['admin', 'physician', 'tech'],
  '/token/:token': ['admin', 'physician', 'tech'],

  // non-route "actions" e.g. creating a new user or changing a facility's designated header image for reports
  'upload_header_logo': ['admin'],
  'add_new_user': ['admin'],
};

/**
 * @param {string} functionality: what a user is attempting to do (go to a report at /report/{UID}, /settings to change some portal setting, etc.)
 * @param {string} role: the role of the user attempting the action
 * @returns: boolean representing whether user is allowed access to this action
 */
const canUserAccessPathOrFunctionality = (functionality, role) => {
  return (routesAllowedUsers[functionality] && role !== null) ? routesAllowedUsers[functionality].includes(role.toLowerCase()) : false;
}

/**
 * takes an actual pathname (pulled from props.location.pathname in the RBAC_Controller functional component) and returns the "matching"
 * pseudo-pathname if it exists (e.g. findMatchingRoute("/report/1.2.3.5") should return "/report/:studyInstanceUid")
 *
 * @param {string} pathname: an actual URL pathname within the portal (e.g. /report/STUDYINSTANCEUID, /viewer/STUDYINSTANCEUID, /settings, etc.)
 * @returns: the name of the "matching" URL pseudo-pathname within routesAllowedUsers (e.g. /report/:studyInstanceUid, /viewer/:studyInstanceUid, /settings, etc.)
 */
const findMatchingRoute = (pathname) => {
  // find matching pathname by replacing all URL parameter aliases in routesAllowedUsers with a known alias ":number" (/report/:studyInstanceUid -> /report/:number),
  // do the same to the pathname parameter (actual portal URL) (e.g. /report/1.2.3.5 -> /report/:number), then return the matching route's pathname (if any)
  let foundMatch = Object.entries(routesAllowedUsers).find(x => x[0].replace(/:\w+/g, ':number') === pathname.replace(/[0-9]+(.[0-9]+)*/g, ':number'));

  return foundMatch ? foundMatch[0] : null;
}

const RBAC_Controller = (props) => {

  useEffect(() => {generateRoutes()}, [props.location.pathname]);

  const [isFinishedChecking, updateFinished] = useState(false);

  const [isRedirecting, setRedirecting] = useState(false);

  let returnRoutes = [];

  let renderRedirectNotification = () => {

    if (!isRedirecting) {

      // if the user is being redirected because they need to log in, store the pathname of the page
      // they were attempting to visit so that we can redirect there rather than studylist once the user
      // has logged in
      sessionStorage.setItem('redirect_url', props.location.pathname);

      notification.open({
        message: <div style={{color: 'rgb(85, 0, 0)'}}><b>Unauthorized Access</b></div>,
        description: 'Access to the requested page is not authorized.',
        style: {background: 'white', color: 'rgb(85, 0, 0)'},
        icon: <Icon type='eye-invisible' />,
      })

      setRedirecting(true);
    }
  }

  async function generateRoutes() {
    let routes = RoutesUtil.getRoutes(props.appConfig);

    // whether user is on a page accessible before logging in
    let needsLogin = ['/', '/login', '/recover'].includes(props.location.pathname);

    let {roleName} = props;

    let promises = routes.map(async ({path, Component}) => {

      // get string version of URL user is currently at
      let pathNameString = '';
      if (typeof path === 'string') {pathNameString = path}
      else if (typeof path === typeof [] && path.includes('/login')) {pathNameString = '/login'}

      // whether the user is allowed to access the page they're currently on
      let canAccess = canUserAccessPathOrFunctionality(pathNameString, roleName);

      return (
        <Route key={path} exact path={path}>
          {({match}) =>
          (
            <CSSTransition
              unmountOnExit
              classNames="fade"
              timeout={300}
              in={match !== null}
              onEnter={() => {props.updateIsLoading(true)}}
              onEntered={() => {props.updateIsLoading(false)}}
            >
              {
                match === null ?
                  (<></>)
                  :
                  (
                    ((((roleName !== '' || roleName !== undefined)) && canAccess) || needsLogin) ?
                      // show user requested component if they are allowed access
                      <Component match={match} location={props.location} roleName={roleName} />
                      :
                      //otherwise, redirect to home screen & display a notification that user cannot access the requested page
                      <Redirect to="/#" />
                  )
              }
            </CSSTransition>
          )
          }
        </Route>
      )
    });

    if (!needsLogin && !canUserAccessPathOrFunctionality(findMatchingRoute(props.location.pathname), props.roleName)) {
      renderRedirectNotification();
    }

    let results = await Promise.all(promises);
    returnRoutes = results;
    props.forceRerenderAfterCheckingPermissions();
    props.passRoutesToOHIFSV(returnRoutes);
    updateFinished(true);
  }

  if (!isFinishedChecking) generateRoutes();
  return returnRoutes;
}

export {RBAC_Controller, canUserAccessPathOrFunctionality, routesAllowedUsers};
