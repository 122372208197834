import classes, {CommandsManager, HotkeysManager} from './classes/';
import metadata from './classes/metadata/';
import cornerstone from './cornerstone.js';
import DICOMWeb from './DICOMWeb';
import {ExtensionManager, MODULE_TYPES} from './extensions';
import hangingProtocols from './hanging-protocols';
import header from './header.js';
import './lib';
import log from './log.js';
import measurements from './measurements';
import object from './object.js';
import redux from './redux/';
import report from './report.js';
import settings from './settings.js';
import string from './string.js';
import studies from './studies/';
import tools from './tools';
import ui from './ui';
import user from './user.js';
import utils from './utils/';

const OHIF = {
  MODULE_TYPES,
  //
  CommandsManager,
  ExtensionManager,
  HotkeysManager,
  //
  utils,
  studies,
  redux,
  report,
  classes,
  metadata,
  header,
  cornerstone,
  string,
  ui,
  user,
  settings,
  object,
  log,
  tools,
  DICOMWeb,
  viewer: {},
  measurements,
  hangingProtocols,
};

export {
  MODULE_TYPES,
  //
  CommandsManager,
  ExtensionManager,
  HotkeysManager,
  //
  utils,
  studies,
  redux,
  report,
  classes,
  metadata,
  header,
  cornerstone,
  string,
  ui,
  user,
  settings,
  object,
  log,
  tools,
  DICOMWeb,
  measurements,
  hangingProtocols,
};
export {OHIF};


export default OHIF;
