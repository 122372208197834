import DICOMWeb from '../DICOMWeb/';
import { api } from 'dicomweb-client';

export default function fetchStudyWorklists(server) {
  const config = {
    url: server.qidoRoot,
    headers: DICOMWeb.getAuthorizationHeader(server),
  };

  const dicomWeb = new api.DICOMwebClient(config);
  return dicomWeb.fetchStudyWorklists().then((results) => results);
}
