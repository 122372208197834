import Studies from './services/qido/studies';

/**
 * Search for studies information by the given filter
 *
 * @param {Object} filter Filter that will be used on search
 * @returns {Promise} resolved with an array of studies information or rejected with an error
 */
export default function searchStudies(server, filter) {
  return Studies(server, filter);
}
