import {ViewerbaseDragDropContext} from '@ohif/ui';
import {NProgress} from '@tanem/react-nprogress';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {matchPath, withRouter} from 'react-router';
import {Route, Switch} from 'react-router-dom';
import {SignoutCallbackComponent} from 'redux-oidc';
import {RBAC_Controller} from '../rbac/roleBasedAccessControl';
import asyncComponent from './components/AsyncComponent.js';
import {Bar, Container} from './components/LoadingBar/';
// Contexts
import AppContext from './context/AppContext';
import './OHIFStandaloneViewer.css';
import NotFound from './routes/NotFound.js';
import * as RoutesUtil from './routes/routesUtil';
import './theme-tide.css';
import './variables.css';

const CallbackPage = asyncComponent(() =>
  import(/* webpackChunkName: "CallbackPage" */ './routes/CallbackPage.js')
);

class OHIFStandaloneViewer extends Component {
  static contextType = AppContext;
  state = {
    isLoading: false,
    routesFromController: []
  };

  static propTypes = {
    history: PropTypes.object.isRequired,
    user: PropTypes.object,
    setContext: PropTypes.func,
    userManager: PropTypes.object,
    location: PropTypes.object,
  };

  componentDidMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      if (this.props.setContext) {
        this.props.setContext(window.location.pathname);
      }
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  updateIsLoading = (bool) => {
    this.setState({isLoading: bool});
  }

  receiveRoutesFromRBAC = (listOfRoutes) => {
    this.setState({routesFromController: listOfRoutes})
  }

  render() {
    const { user, userManager } = this.props;
    const { appConfig = {} } = this.context;
    const userNotLoggedIn = userManager && (!user || user.expired);
    if (userNotLoggedIn) {
      const pathname = this.props.location.pathname;

      if (pathname !== '/callback') {
        sessionStorage.setItem('ohif-redirect-to', pathname);
      }

      return (
          <Switch>
            <Route exact path="/silent-refresh.html" onEnter={RoutesUtil.reload} />
            <Route
              exact
              path="/logout-redirect"
              render={() => (
                <SignoutCallbackComponent
                  userManager={userManager}
                  successCallback={() => console.log('Signout successful')}
                  errorCallback={(error) => {
                    console.warn(error);
                    console.warn('Signout failed');
                  }}
                />
              )}
            />
            <Route path="/callback" render={() => <CallbackPage userManager={userManager} />} />
            <Route
              component={() => {
                userManager.getUser().then((user) => {
                  if (user) {
                    userManager.signinSilent();
                  } else {
                    userManager.signinRedirect();
                  }
                });

                return null;
              }}
            />
          </Switch>
      );
    }

    /**
     * Note: this approach for routing is caused by the conflict between
     * react-transition-group and react-router's <Switch> component.
     *
     * See http://reactcommunity.org/react-transition-group/with-react-router/
     */
    const routes = RoutesUtil.getRoutes(appConfig);

    const currentPath = this.props.location.pathname;
    const noMatchingRoutes = !routes.find((r) =>
      matchPath(currentPath, {
        path: r.path,
        exact: true,
      })
    );

    return (
      <>
        <NProgress isAnimating={this.state.isLoading}>
          {({ isFinished, progress, animationDuration }) => (
            <Container isFinished={isFinished} animationDuration={animationDuration}>
              <Bar progress={progress} animationDuration={animationDuration} />
            </Container>
          )}
        </NProgress>
        <Route exact path="/silent-refresh.html" onEnter={RoutesUtil.reload} />
        <Route exact path="/logout-redirect.html" onEnter={RoutesUtil.reload} />
        {!noMatchingRoutes &&
          <RBAC_Controller
            roleName={sessionStorage.getItem('token') ? JSON.parse((atob(sessionStorage.getItem('token').split('.')[1])))['roleName'] : null}
            location={this.props.location}
            appConfig={appConfig}
            updateIsLoading={this.updateIsLoading}
            forceRerenderAfterCheckingPermissions={() => {this.forceUpdate()}}
            passRoutesToOHIFSV={this.receiveRoutesFromRBAC}/>}
        {!this.state.routesFromController.some(x => x === undefined) ? this.state.routesFromController : ''}

        {/*Previous way of mapping across routes before implementing role-based access control*/}
        {/*{!noMatchingRoutes &&
          routes.map(({ path, Component }) => {
            return (
              <Route key={path} exact path={path}>
                {({ match }) => (
                  <CSSTransition
                    in={match !== null}
                    timeout={300}
                    classNames="fade"
                    unmountOnExit
                    onEnter={() => {
                      this.setState({
                        isLoading: true,
                      });
                    }}
                    onEntered={() => {
                      this.setState({
                        isLoading: false,
                      });
                    }}
                  >
                    {match === null ? (
                      <></>
                    ) : (
                      <Component match={match} location={this.props.location} roleName={this.props.roleName}/>
                    )}
                  </CSSTransition>
                )}
              </Route>
            )
          })
        }*/}
        {noMatchingRoutes && <NotFound />}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.oidc.user,
  };
};

const ConnectedOHIFStandaloneViewer = connect(mapStateToProps, null)(OHIFStandaloneViewer);

export default ViewerbaseDragDropContext(withRouter(ConnectedOHIFStandaloneViewer));
