import cornerstone from 'cornerstone-core';
import cornerstoneTools from 'cornerstone-tools';
import cornerstoneWADOImageLoader from 'cornerstone-wado-image-loader';
import log from '../log.js';
import getImageId from '../utils/getImageId.js';

export class StudyPrefetcher {
  constructor(studies) {
    this.studies = studies || [];
    this.prefetchDisplaySetsTimeout = 300;
    this.lastActiveViewportElement = null;

    cornerstone.events.addEventListener(
      'cornerstoneimagecachefull.StudyPrefetcher',
      this.cacheFullHandler
    );
  }

  destroy() {
    this.stopPrefetching();
    cornerstone.imageCache.purgeCache();
    cornerstoneWADOImageLoader.compressedImageCache.purgeCache();

    cornerstone.events.removeEventListener(
      'cornerstoneimagecachefull.StudyPrefetcher',
      this.cacheFullHandler
    );
  }

  static getInstance() {
    if (!StudyPrefetcher.instance) {
      StudyPrefetcher.instance = new StudyPrefetcher([]);
    }

    return StudyPrefetcher.instance;
  }

  setStudies(studies) {
    this.stopPrefetching();

    // We closed a study tab, so remove its images from both caches.
    if (studies.length < this.studies.length) {
      const removedStudy = this.studies.filter(
        (x) => studies.map((y) => y.studyInstanceUid).indexOf(x.studyInstanceUid) < 0
      );

      let imageIds = this.getImageIdsFromDisplaySets(removedStudy[0].displaySets);

      for (let i = 0; i < imageIds.length; i++) {
        const imageId = imageIds[i];

        // Remove study from imageCache.
        if (cornerstone.imageCache.imageCache[imageId]) {
          cornerstone.imageCache.removeImageLoadObject(imageId);
        }

        // Remove study from compressedImageCache.
        if (cornerstoneWADOImageLoader.compressedImageCache.imageCache[imageId]) {
          cornerstoneWADOImageLoader.compressedImageCache.removeImageLoadObject(imageId);
        }
      }
    }

    this.studies = studies;
  }

  prefetch() {
    if (!this.studies || !this.studies.length || this.studies.length > 1) {
      return;
    }

    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      // do not prefetch for mobile since it crashes frequently from memory issues
    } else {
      this.stopPrefetching();
      this.prefetchDisplaySets();
    }
  }

  stopPrefetching() {
    cornerstoneTools.requestPoolManager.clearRequestStack('prefetch-force-stop');
    cornerstoneTools.requestPoolManager.clearRequestStack('compress');
  }

  prefetchDisplaySetsAsync(timeout) {
    timeout = timeout || this.prefetchDisplaySetsTimeout;

    clearTimeout(this.prefetchDisplaySetsHandler);
    this.prefetchDisplaySetsHandler = setTimeout(() => {
      this.prefetchDisplaySets();
    }, timeout);
  }

  prefetchDisplaySets() {
    // TODO: Allow passing in config
    var config = {
      order: 'closest',
    };

    // Fill up our prefetch queue with current study tabs, prioritizing active study.
    const activeTab = sessionStorage['viewerActiveTab'] || 888;
    const activeStudy = this.studies.find((x) => x.studyInstanceUid === activeTab);

    if (activeStudy) {
      var displaySets = activeStudy.displaySets;
      for (let i = 0; i < this.studies.length; i++) {
        const study = this.studies[i];

        if (study.studyInstanceUid !== activeTab) {
          displaySets = displaySets.concat(study.displaySets);
        }
      }

      //const displaySetsToPrefetch = this.getClosestDisplaySets(displaySets, null, displaySets.length);
      //const displaySetsToPrefetch = this.getDisplaySetsToPrefetch(config);
      this.sendFrameRequestsFromDisplaySets(displaySets);

      //this.prefetchImageIds(imageIds);
    }
  }

  prefetchImageIds(imageIds) {
    const nonCachedImageIds = this.filterCachedImageIds(imageIds);
    const requestPoolManager = cornerstoneTools.requestPoolManager;
    const requestType = 'fetch';
    const preventCache = false;
    const noop = () => { };
    nonCachedImageIds.forEach((imageId) => {
      requestPoolManager.addRequest({}, imageId, requestType, preventCache, noop, noop);
    });

    requestPoolManager.startGrabbing();
  }

  getStudy(image) {
    const studyMetadata = cornerstone.metaData.get('study', image.imageId);
    return OHIF.viewer.Studies.find(
      (study) => study.studyInstanceUid === studyMetadata.studyInstanceUid
    );
  }

  getSeries(study, image) {
    const seriesMetadata = cornerstone.metaData.get('series', image.imageId);
    const studyMetadata = OHIF.viewerbase.getStudyMetadata(study);

    return studyMetadata.getSeriesByUID(seriesMetadata.seriesInstanceUid);
  }

  getInstance(series, image) {
    const instanceMetadata = cornerstone.metaData.get('instance', image.imageId);
    return series.getInstanceByUID(instanceMetadata.sopInstanceUid);
  }

  getActiveDisplaySet(displaySets, instance) {
    return displaySets.find((displaySet) => {
      return displaySet.images.some((displaySetImage) => {
        return displaySetImage.sopInstanceUid === instance.sopInstanceUid;
      });
    });
  }

  getDisplaySetsToPrefetch(config) {
    //var image = this.getActiveViewportImage();

    //if (!image || !config || !config.displaySetCount) {
    //  return [];
    //}
    /*const study = this.getStudy(image);
    const series = this.getSeries(study, image);
    const instance = this.getInstance(series, image);*/

    var displaySets = this.studies[0].displaySets;

    var activeDisplaySet = null; //this.getActiveDisplaySet(displaySets, instance);

    var prefetchMethodMap = {
      topdown: 'getFirstDisplaySets',
      downward: 'getNextDisplaySets',
      closest: 'getClosestDisplaySets',
    };
    var prefetchOrder = config.order;
    var methodName = prefetchMethodMap[prefetchOrder];
    var getDisplaySets = this[methodName];

    if (!getDisplaySets) {
      if (prefetchOrder) {
        log$1.warn('Invalid prefetch order configuration ('.concat(prefetchOrder, ')'));
      }

      return [];
    }

    return getDisplaySets.call(this, displaySets, activeDisplaySet, displaySets.length);
  }

  getFirstDisplaySets(displaySets, activeDisplaySet, displaySetCount) {
    const length = displaySets.length;
    const selectedDisplaySets = [];

    for (let i = 0; i < length && displaySetCount; i++) {
      const displaySet = displaySets[i];

      if (displaySet !== activeDisplaySet) {
        selectedDisplaySets.push(displaySet);
        displaySetCount--;
      }
    }

    return selectedDisplaySets;
  }

  getNextDisplaySets(displaySets, activeDisplaySet, displaySetCount) {
    const activeDisplaySetIndex = displaySets.indexOf(activeDisplaySet);
    const begin = activeDisplaySetIndex + 1;
    const end = Math.min(begin + displaySetCount, displaySets.length);

    return displaySets.slice(begin, end);
  }

  getClosestDisplaySets(displaySets, activeDisplaySet, displaySetCount) {
    const activeDisplaySetIndex = displaySets.indexOf(activeDisplaySet);
    const length = displaySets.length;
    const selectedDisplaySets = [];
    let left = activeDisplaySetIndex - 1;
    let right = activeDisplaySetIndex + 1;

    while ((left >= 0 || right < length) && displaySetCount) {
      if (left >= 0) {
        selectedDisplaySets.push(displaySets[left]);
        displaySetCount--;
        left--;
      }

      if (right < length && displaySetCount) {
        selectedDisplaySets.push(displaySets[right]);
        displaySetCount--;
        right++;
      }
    }

    return selectedDisplaySets;
  }

  sendFrameRequestsFromDisplaySets(displaySets) {
    const cache = sessionStorage.getItem('cache');

    const requestPoolManager = cornerstoneTools.requestPoolManager;
    const preventCache = false;
    const noop = () => { };

    displaySets.forEach(function (displaySet, displaySetIndex) {
      displaySet.images.forEach(function (image) {
        var _imageId = getImageId(image);

        if (cache && cache === 'efficient') {
          for (let i = 1; i < displaySet.numImageFrames + 1; i++) {
            const requestType = displaySetIndex === 0 ? 'prefetch' : 'compress';

            requestPoolManager.addRequest(
              {},
              _imageId.replace('frames/1', `frames/${i}`),
              requestType,
              preventCache,
              noop,
              noop
            );
          }
        } else {
          for (let i = 1; i < displaySet.numImageFrames + 1; i++) {
            const requestType = 'prefetch';

            requestPoolManager.addRequest(
              {},
              _imageId.replace('frames/1', `frames/${i}`),
              requestType,
              preventCache,
              noop,
              noop
            );
          }
        }
      });
    });

    requestPoolManager.startGrabbing();
  }

  getImageIdsFromDisplaySets(displaySets) {
    var imageIds = []; // TODO: This duplicates work done by the stack manager

    displaySets.forEach(function (displaySet) {
      displaySet.images.forEach(function (image) {
        var _imageId = getImageId(image);

        for (let i = 1; i < displaySet.numImageFrames + 1; i++) {
          imageIds.push(_imageId.replace('frames/1', `frames/${i}`));
        }
      });
    });

    return imageIds;
  }

  filterCachedImageIds(imageIds) {
    var _this4 = this;

    return imageIds.filter(function (imageId) {
      return !_this4.isImageCached(imageId);
    });
  }

  isImageCached(imageId) {
    const image = cornerstone.imageCache.imageCache[imageId];
    return image && image.sizeInBytes;
  }

  cacheFullHandler = () => {
    log.warn('Cache full');
    this.stopPrefetching();
  };
}
