import { Form, Icon, Input } from 'antd';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Modal from 'react-bootstrap-modal';
import 'react-bootstrap-modal/lib/css/rbm-patch.css';
import { withTranslation } from '../../utils/LanguageProvider';
// TODO: Is this the only component importing these?
import './../../design/styles/common/modal.styl';
import './ChangePasswordModal.styl';



class ChangePasswordModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: '',
      password: '',
      passwordConfirm: '',
      passwordOld: ''
    }
  }
  // TODO: Make this component more generic to allow things other than W/L and hotkeys...
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onCancel: PropTypes.func,
  };

  static defaultProps = {
    isOpen: false,
  };

  handleChangeState = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  }

  handleChangePassword = () => {
    let error = '';

    if (this.state.password.trim() === '' || this.state.passwordConfirm.trim() === '' || this.state.passwordOld.trim() === '') {
      error = 'Passwords cannot be blank';
    }

    if (this.state.password !== this.state.passwordConfirm) {
      error = 'Passwords do not match';
    }

    if (error === '') {
      const data = JSON.stringify({
        password: this.state.password,
        passwordOld: this.state.passwordOld
      });

      this.setState({ error: '' }, function () {
        this.props.onSubmit(data).then(result => {
          result = JSON.parse(result);

          if (result.error) {
            this.setState({ error: result.message });
          } else {
            this.props.onToggle();

            this.setState({
              password: '',
              passwordConfirm: '',
              passwordOld: ''
            });
          }
        });
      });
    } else {
      this.setState({ error });
    }
  }

  render() {
    const { t } = this.props;
    return (
      <Modal
        show={this.props.isOpen}
        onHide={this.props.onCancel}
        aria-labelledby="ModalHeader"
        className="AboutModal modal fade themed in"
        backdrop={false}
        large={true}
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t('Change your password')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Input id='passwordOld' type='password' style={{ marginBottom: '15px', maxWidth: '500px' }} prefix={<Icon type='lock' />} placeholder='Old password' value={this.state.passwordOld} onChange={this.handleChangeState} />
            <Input id='password' type='password' style={{ marginBottom: '15px', maxWidth: '500px' }} prefix={<Icon type='lock' />} placeholder='Password' value={this.state.password} onChange={this.handleChangeState} />
            <Form.Item help={<div style={{ color: 'indianred' }}>{this.state.error}</div>}>
              <Input id='passwordConfirm' type='password' style={{ maxWidth: '500px' }} prefix={<Icon type='lock' />} placeholder='Confirm password' value={this.state.passwordConfirm} onChange={this.handleChangeState} />
            </Form.Item>

            <button style={{ margin: '5px 0px 25px 0px' }} className="search-button-homepage" onClick={this.handleChangePassword}>
              Change password
            </button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

const connectedComponent = withTranslation('ChangePasswordModal')(ChangePasswordModal);
export { connectedComponent as ChangePasswordModal };
export default connectedComponent;
