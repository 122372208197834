import OHIF from '@ohif/core';
import React from 'react';
import Popout from 'react-popout';
import { connect } from 'react-redux';

const { setReportWindow } = OHIF.redux.actions;

class MyWindowPortal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      study: '',
      title: '',
      url: '',
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.study && this.props.study !== prevProps.study) {
      const data = OHIF.utils.studyMetadataManager.all(null)[0]._data;

      // pacs.lahearts.com - old cardio report manager report
      //
      const studyencoding = data.seriesList[0].instances[0].studyurl;
      const studyId = studyencoding.split('.')[0];
      const url = `https://crm.cisbr.com/CardioReportManager/unsecure/studyReport2.aspx?STUDYID=${studyId}`;

      // pacs.lahearts.com - new report
      //
      // const token = sessionStorage.getItem('token');
      // const studyInstanceUid = this.props.study.studyInstanceUid;
      // const url = `http://localhost:5000/token/${token}?redirect=/report/${studyInstanceUid}`;
      // const url = `https://pacs.lahearts.com/token/${token}?redirect=/report/${studyInstanceUid}`;

      // pacs.mindcreek.com: Changes report to study type
      //
      // ******NEED TO JUST SET URLPATH TO STUDYTYPEID IF IT EXISTS; ELSE SET TO 1
      //
      // let urlPath = '1';
      // if (data.seriesList[0].instances[0].studyTypeId === 5) {
      //   urlPath = '5';
      // }

      // if (data.seriesList[0].instances[0].studyTypeId === 24) {
      //   urlPath = '24';
      // }

      // const url = `https://pacs.mindcreek.com/report/${urlPath}`;

      this.setState({
        study: this.props.study,
        title: data.patientName,
        url,
      });
    }
  }

  componentWillUnmount() {
    if (this.externalWindow) {
      this.externalWindow.close();
    }
  }

  handleClosePopout = () => {
    this.props.setReportWindow({ open: false });
  };

  render() {
    if (this.props.open) {
      const height = window.innerHeight;
      const width = window.innerWidth / 2;

      return (
        <Popout
          title={`Report - ${this.state.title}`}
          options={{ right: '0px', height, width }}
          onClosing={this.handleClosePopout}
        >
          <div style={{ height: '100%', width: '100%', overflow: 'hidden' }}>
            <iframe
              style={{
                height: '100%',
                width: '100%',
              }}
              src={this.state.url}
            />
          </div>
        </Popout>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  return state.viewer;
};

const mapDispatchToProps = (dispatch) => {
  return {
    setReportWindow: (data) => {
      dispatch(setReportWindow(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyWindowPortal);
