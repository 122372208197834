import init from './init.js';

export default {
  /**
   * Only required property. Should be a unique value across all extensions.
   */
  id: 'measurements-table',

  preRegistration(configuration = {}) {
    init(configuration);
  },
  getPanelModule() {
    return {
      menuOptions: [
        // {
        //   icon: 'list',
        //   label: 'Report',
        //   target: 'measurement-panel',
        // },
      ],
      components: [
        // {
        //   id: 'measurement-panel',
        //   // component: Form,
        //   component: () => null,
        // },
      ],
      defaultContext: ['VIEWER'],
    };
  },
};
