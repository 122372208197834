import OHIF from '@ohif/core';
import cornerstone from 'cornerstone-core';
import cornerstoneWADOImageLoader from 'cornerstone-wado-image-loader';
import dicomParser from 'dicom-parser';
import version from './version.js';

let homepage;
const { process } = window;
if (process && process.env && process.env.PUBLIC_URL) {
  homepage = process.env.PUBLIC_URL;
}

window.info = {
  version,
  homepage,
};

// For debugging
//if (process.env.node_env === 'development') {
window.cornerstone = cornerstone;
window.cornerstoneWADOImageLoader = cornerstoneWADOImageLoader;
//}

// Rule of thumb: 1 GB of cache holds ~1050 images (frames).
const cache = sessionStorage.getItem('cache');
if (cache && cache === 'fast') {
  cornerstone.imageCache.setMaximumSizeBytes(25073741824); // 25 GB
  cornerstoneWADOImageLoader.compressedImageCache.setMaximumSizeBytes(1073741824); // default 1 GB
} else {
  cornerstone.imageCache.setMaximumSizeBytes(1073741824); // default 1 GB
  cornerstoneWADOImageLoader.compressedImageCache.setMaximumSizeBytes(25073741824); // 25 GB
}

cornerstoneWADOImageLoader.external.cornerstone = cornerstone;
cornerstoneWADOImageLoader.external.dicomParser = dicomParser;

cornerstoneWADOImageLoader.configure({
  beforeSend: function (xhr) {
    const headers = OHIF.DICOMWeb.getAuthorizationHeader();

    if (headers.Authorization) {
      xhr.setRequestHeader('Authorization', headers.Authorization);
    }
  },
});
