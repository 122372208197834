import './OHIFLogo.css';

import { Icon } from '@ohif/ui';
import React from 'react';

function OHIFLogo() {
  return (
    <div className="header-brand">
      <Icon name="ohif-logo" className="header-logo-image" />
      <div className="header-logo-text">Cardiac Information Services</div>
    </div>
  );
}

export default OHIFLogo;
