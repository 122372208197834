import {api} from 'dicomweb-client';

let settings =
{
  getClientList: () => {
    const config = {
      url: ' ',
      headers: {},
    };

    const dicomWeb = new api.DICOMwebClient(config);
    return dicomWeb.getClientList().then((result) => {return result});
  },
  getFacilityList: () => {
    const config = {
      url: ' ',
      headers: {}
    };

    const dicomWeb = new api.DICOMwebClient(config);
    return dicomWeb.getFacilityList().then((result) => {return result});
  },
  getPhysicianList: () => {
    const config = {
      url: ' ',
      headers: {}
    };

    const dicomWeb = new api.DICOMwebClient(config);
    return dicomWeb.getPhysicianList().then((result) => {return result});
  },
  getReferringPhysicianList: () => {
    const config = {
      url: ' ',
      headers: {}
    };

    const dicomWeb = new api.DICOMwebClient(config);
    return dicomWeb.getReferringPhysicianList().then((result) => {return result});
  },
  getTechnicianList: () => {
    const config = {
      url: ' ',
      headers: {}
    };

    const dicomWeb = new api.DICOMwebClient(config);
    return dicomWeb.getTechnicianList().then((result) => {return result});
  },
  getPatientList: (data) => {
    const config = {
      url: ' ',
      headers: {}
    };

    const dicomWeb = new api.DICOMwebClient(config);
    return dicomWeb.getPatientList(data).then((result) => {return result});
  },
  updatePatient: (data) => {
    const config = {
      url: ' ',
      headers: {}
    };

    const dicomWeb = new api.DICOMwebClient(config);
    return dicomWeb.updatePatient(data).then((result) => {return result});
  },
  getStatusList: () => {
    const config = {
      url: ' ',
      headers: {}
    };

    const dicomWeb = new api.DICOMwebClient(config);
    return dicomWeb.getStatusList().then((result) => {return result});
  },
  getUserList: () => {
    const config = {
      url: ' ',
      headers: {}
    };

    const dicomWeb = new api.DICOMwebClient(config);
    return dicomWeb.getUserList().then((result) => {return result});
  },
  getStudyTypeList: () => {
    const config = {
      url: ' ',
      headers: {}
    };

    const dicomWeb = new api.DICOMwebClient(config);
    return dicomWeb.getStudyTypeList().then((result) => {return result});
  },
  getRoleList: () => {
    const config = {
      url: ' ',
      headers: {}
    };

    const dicomWeb = new api.DICOMwebClient(config);
    return dicomWeb.getRoleList().then((result) => {return result});
  },
  fetchPatientFromKey: (data) => {
    const config = {
      url: ' ',
      headers: {}
    };

    const dicomWeb = new api.DICOMwebClient(config);
    return dicomWeb.fetchPatientFromKey(data).then((result) => {return result});
  },
  getHeaderLogo: () => {
    const config = {
      url: ' ',
      headers: {}
    };

    const dicomWeb = new api.DICOMwebClient(config);
    return dicomWeb.getHeaderLogo().then((result) => {return result});
  },
  setHeaderLogo: (data) => {
    const config = {
      url: ' ',
      headers: {}
    };

    const dicomWeb = new api.DICOMwebClient(config);
    return dicomWeb.setHeaderLogo(data).then((result) => {return result});
  },
  submitNewUser: (data) => {
    const config = {
      url: ' ',
      headers: {}
    };

    const dicomWeb = new api.DICOMwebClient(config);
    return dicomWeb.submitNewUser(data).then((result) => {return result});
  },
  updateExistingUser: (data) => {
    const config = {
      url: ' ',
      headers: {}
    };

    const dicomWeb = new api.DICOMwebClient(config);
    return dicomWeb.updateExistingUser(data).then((result) => {return result});
  },

  submitNewTechnician: (data) => {
    const config = {
      url: ' ',
      headers: {}
    };

    const dicomWeb = new api.DICOMwebClient(config);
    return dicomWeb.submitNewTechnician(data).then((result) => {return result});
  },

  updateExistingTechnician: (data) => {
    const config = {
      url: ' ',
      headers: {}
    };

    const dicomWeb = new api.DICOMwebClient(config);
    return dicomWeb.updateExistingTechnician(data).then((result) => {return result});
  },

  submitNewPhysician: (data) => {
    const config = {
      url: ' ',
      headers: {}
    };

    const dicomWeb = new api.DICOMwebClient(config);
    return dicomWeb.submitNewPhysician(data).then((result) => {return result});
  },

  updateExistingPhysician: (data) => {
    const config = {
      url: ' ',
      headers: {}
    };

    const dicomWeb = new api.DICOMwebClient(config);
    return dicomWeb.updateExistingPhysician(data).then((result) => {return result});
  },

  linkPhysicianUserAccount: (data) => {
    const config = {
      url: ' ',
      headers: {}
    };

    const dicomWeb = new api.DICOMwebClient(config);
    return dicomWeb.linkPhysicianUserAccount(data).then((result) => {return result});
  },

  submitNewReferringPhysician: (data) => {
    const config = {
      url: ' ',
      headers: {}
    };

    const dicomWeb = new api.DICOMwebClient(config);
    return dicomWeb.submitNewReferringPhysician(data).then((result) => {return result});
  },

  updateExistingReferringPhysician: (data) => {
    const config = {
      url: ' ',
      headers: {}
    };

    const dicomWeb = new api.DICOMwebClient(config);
    return dicomWeb.updateExistingReferringPhysician(data).then((result) => {return result});
  },

  linkTechnicianUserAccount: (data) => {
    const config = {
      url: ' ',
      headers: {}
    };

    const dicomWeb = new api.DICOMwebClient(config);
    return dicomWeb.linkTechnicianUserAccount(data).then((result) => {return result});
  },

  //NEW SETTINGS PAGE REQUEST FOR STRUCTURED REPORTING MANAGEMENT
  fetchStructuredReportDatapoints: () => {
    const config = {
      url: ' ',
      headers: {}
    };

    const dicomWeb = new api.DICOMwebClient(config);
    return dicomWeb.fetchStructuredReportDatapoints().then((result) => {return result});
  },
  fetchStructuredReportDatapointAliases: () => {
    const config = {
      url: ' ',
      headers: {}
    };

    const dicomWeb = new api.DICOMwebClient(config);
    return dicomWeb.fetchStructuredReportDatapointAliases().then((result) => {return result});
  },
  fetchStructuredReportTemplates: () => {
    const config = {
      url: ' ',
      headers: {}
    };

    const dicomWeb = new api.DICOMwebClient(config);
    return dicomWeb.fetchStructuredReportTemplates().then((result) => {return result});
  },
  saveUpdatedSRAlias: (data) => {
    const config = {
      url: ' ',
      headers: {}
    };

    const dicomWeb = new api.DICOMwebClient(config);

    return dicomWeb.saveUpdatedSRAlias(data).then((result) => result);

  },
  fetchWorklistFromFacilityId: (data) => {
    const config = {
      url: ' ',
      headers: {}
    };

    const dicomWeb = new api.DICOMwebClient(config);

    return dicomWeb.fetchWorklistFromFacilityId(data).then((result) => result);

  },

  searchForMatchingStudyAnalytics: (data) => {
    const config = {
      url: ' ',
      headers: {}
    };

    const dicomWeb = new api.DICOMwebClient(config);

    return dicomWeb.searchForMatchingStudyAnalytics(data).then((result) => result);
  },

  toggleDisableEnableUser: (data) => {
    const config = {
      url: ' ',
      headers: {}
    };

    const dicomWeb = new api.DICOMwebClient(config);

    return dicomWeb.toggleDisableEnableUser(data).then((result) => result);
  },

  toggleDisableEnableTechnician: (data) => {
    const config = {
      url: ' ',
      headers: {}
    };

    const dicomWeb = new api.DICOMwebClient(config);

    return dicomWeb.toggleDisableEnableTechnician(data).then((result) => result);
  },

  toggleDisableEnablePhysician: (data) => {
    const config = {
      url: ' ',
      headers: {}
    };

    const dicomWeb = new api.DICOMwebClient(config);

    return dicomWeb.toggleDisableEnablePhysician(data).then((result) => result);
  },

  fetchPatientsFromLegacyPacsDB: (data) => {
    const config = {
      url: ' ',
      headers: {}
    };

    const dicomWeb = new api.DICOMwebClient(config);

    return dicomWeb.fetchPatientsFromLegacyPacsDB(data).then((result) => result);
  },

  fetchStudiesOfLegacyDBPatient: (data) => {
    const config = {
      url: ' ',
      headers: {}
    };

    const dicomWeb = new api.DICOMwebClient(config);

    return dicomWeb.fetchStudiesOfLegacyDBPatient(data).then((result) => result);
  },

  fetchLegacyDBLists: (data) => {
    const config = {
      url: ' ',
      headers: {}
    };

    const dicomWeb = new api.DICOMwebClient(config);

    return dicomWeb.fetchLegacyDBLists(data).then((result) => result);
  },

  fetchStudiesFromPatientId: (data) => {
    const config = {
      url: ' ',
      headers: {}
    };

    const dicomWeb = new api.DICOMwebClient(config);

    return dicomWeb.fetchStudiesFromPatientId(data).then((result) => result);
  },

  reassignStudyToNewPatientKey: (data) => {
    const config = {
      url: ' ',
      headers: {}
    };

    const dicomWeb = new api.DICOMwebClient(config);

    return dicomWeb.reassignStudyToNewPatientKey(data).then((result) => result);
  },
}

export default settings;
