// Commenting this out for now since it looks like Rollup is pulling in the
// Node.js version instead of the Browser version of this package
//import { btoa } from 'isomorphic-base64';

/**
 * Returns the Authorization header as part of an Object.
 *
 * @export
 * @param {Object} [server={}]
 * @param {Object} [server.requestOptions]
 * @param {string|function} [server.requestOptions.auth]
 * @returns {Object} { Authorization }
 */
export default function getLoginAccessHeader(accessToken) {
  var headers = {}; // Check for OHIF.user since this can also be run on the server

  headers.Authorization = 'Bearer '.concat(accessToken);
  return headers;
}
