import {api} from 'dicomweb-client';

let tools =
{
  getToolState: () => {
    const config= {
      url : ' ',
      headers: {},
    };

    const dicomWeb = new api.DICOMwebClient(config);
    return dicomWeb.getToolState().then((result) => {return result});
  },
  saveToolState: (data) => {
    const config= {
      url : ' ',
      headers: {},
    };

    const dicomWeb = new api.DICOMwebClient(config);
    return dicomWeb.saveToolState(data).then((result) => {return result});
  },
}

export default tools;
